<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
    <div v-if="isPageLoading">
        <pulse loading="isPageLoading"></pulse>
    </div>
    <div class="row" v-else>
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="col-lg-12">
                <div class="row me-1">
                    <div class="col-md-12 text-center">
                        <h4 v-if="msg_title" class="text-center text-danger">{{msg_title}}</h4>
                    </div>
                    <div class="col-lg-4" v-for="Subscription in Subscriptionlists">
                        <div v-bind:class="{
                               'bg-gradient-danger card-img-holder block inner': Subscription.background == 'Red',
                               'bg-gradient-info block inner': Subscription.background == 'Blue',
                               'bg-gradient-success block inner': Subscription.background == 'Green',
                               }">

                            <div class="text-center mt-2">
                                <span class="text-white text-center">{{Subscription.plan_description}}</span>
                            </div>
                            <h3 class="text-center text-white mt-1">{{Subscription.plan_name}}</h3>

                            <!-- CONTENT -->
                            <div class="content">
                                <p class="text-center" v-if="Subscription.plan_discounted_price != 0">
                                    <span class="price span" >${{Subscription.plan_discounted_price}}</span>
                                    <sub class="price sub">/ {{convertDaysToMonthYear(Subscription.plan_validity)}}</sub>
                                    <sub class="price sub">&nbsp&nbsp&nbsp<strike>${{Subscription.plan_price}}</strike></sub>
                                </p>
                                <p class="text-center" v-else>
                                    <span class="price span">${{Subscription.plan_price}}</span>
                                    <sub class="price sub">/ {{convertDaysToMonthYear(Subscription.plan_validity)}}</sub>
                                </p>
                            </div>
                            <!-- /CONTENT -->
                            <!-- FEATURES -->
                            <ul class="list-star text-white" v-for="feature in Subscription.features">
                                <li>{{feature.feature_name}} {{feature.feature_desc}}</li>
                            </ul>
                            <ul v-if="Subscription.support_in_time" class="list-star text-white">
                                <li>Support in {{Subscription.support_in_time}} Days</li>
                            </ul>
                            <!-- /FEATURES -->
                            <!-- PT-FOOTER -->
                            <div v-if="Subscription.is_auto_renewal"class="text-center mt-2">
                                <span class="text-white text-center">Auto Renewal</span>
                            </div>
                            <br/>
                            <div class="conentbottom">
                                <b-button v-if="is_btn_spinner && Subscription.id == button_subscription_id" variant="primary" disabled>
                                    <b-spinner small type="grow"></b-spinner>Loading...
                                </b-button>
                                <button v-else type="button" @click="redeemSubscription(Subscription.id)" class="btn btn-outline-danger btn-fw text-white">Buy Subscription</button>
                            </div>
                            <!-- /PT-FOOTER -->
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
    <b-modal id="modal-prevent-closing" ref="modal" title="" @ok="handleOk"  centered>
      <b-form-input v-model="patientno" placeholder="Enter patient number"></b-form-input>
      <p v-if="errormsg != ''" class="err">{{errormsg}}</p>
    </b-modal>
</div>
</template>
<script>
import router from "../../router";
import SideBar from "@/components/SideBar.vue";
import Subscription from "@/components/Subscription.vue";
import DateRangePicker from 'vue2-daterange-picker'
import { mapActions} from "vuex";
import { required, numeric } from "vuelidate/lib/validators";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import Vue from 'vue'
import { PulseSpinner } from 'vue-spinners'
Vue.component('pulse', PulseSpinner)
import string from "../../constant/strings.js"

export default {
  name:'OPDS',
  title: string.PAGE_TITLE_SUBSCRIPTION,
  components: {
    SideBar,
    DateRangePicker,
    Multiselect,
    Subscription
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  	const maxDate = new Date(today)

    return {
      Subscriptionlists : [],
      opdlists:[],
      moment,
      listPages:0,
      msg_title:'',
      activePage:1,
      patientno:'',
      errormsg:'',
      is_API_call_running:false,
      searchtext:'',
      myTimer:null,
      isPageLoading: true,
      is_btn_spinner: false,
      button_subscription_id: '',
      dateRange:{
        startDate: today,
        endDate: today
      },
      response_code:200,
      errorMessage : "No Data Found.",
      opd_status_list:['All','Waiting', 'Under Treatment', 'Waiting For Report', 'Waiting For Prescription', 'Completed', 'InActive'],
      opd_filter_status: "All",
      MAXDATE:maxDate,
    }
  },
  mounted() {
    this.subcriptionList();
    document.body.classList.add('sidebar-icon-only');
  },
  
  methods:{
    ...mapActions("hospital",["subcriptionListData","redeemSubscriptionData","getAdminDetailsData"]),

    redeemSubscription(plan_id){
           this.is_btn_spinner = true
           this.button_subscription_id = plan_id
           var bodyFormData = new FormData();
           bodyFormData.append('plan_id', plan_id);
           this.redeemSubscriptionData(bodyFormData).then((response) => {
                this.is_btn_spinner = false
                this.button_subscription_id = ''
                if(response.response_code == 200){
                   console.log(response.order_id)
                   console.log(response.order_id != undefined)
                   console.log(response.order_id != undefined && response.order_id.length > 0)
                   if(response.order_id){
                        this.startPaymentProcess(response.order_id, response.client_name, response.client_email, response.client_mobile)
                   } else {
                        this.$toasted.success(response.message, {duration: 2000,});
                        router.push({ name: 'home' }).catch(() => {})
                   }
                }
                else{
                   this.$toasted.error(response.message, {duration: 2000,});
                }
                this.$refs['modal-subcription'].hide()
            });
    },

    startPaymentProcess(order_id, client_name, client_email, client_mobile){
        try {
                // amount: amount,
                // currency: currency,
                // name: "Your Company Name",
                // description: "Test Transaction",
            const options = {
                 key: process.env.VUE_APP_RAZORPAY_KEY_ID,  // Replace with your Razorpay Key ID
                 order_id: order_id,  // Razorpay Order ID
                 handler: this.paymentSuccessHandler,  // Callback function on successful payment
                 prefill: {
                    name: client_name,
                    email: client_email,
                    contact: client_mobile,
                },
             }
            const razorpay = new Razorpay(options);
            razorpay.open();
        } catch (error) {
            console.error("Error while creating order:", error);
        }
    },

    paymentSuccessHandler(response) {
        router.push({ name: 'home' }).catch(() => {})
    },

    subcriptionList(){
      this.isPageLoading = true
      var bodyFormData = new FormData();
      this.getAdminDetailsData(bodyFormData).then((response) => {
        if (response.response_code == 200) {          
            this.data = response.data;
            this.role = response.role;
            this.subscription_plan = response.subscription_plan;

            if(this.subscription_plan != undefined) {
                this.isPageLoading = false
                if(this.subscription_plan.is_subscription_expired) {
                    this.subcriptionListData().then((response) => {
                      this.Subscriptionlists = response.data;
                      this.msg_title = response.message;
                    });
                } else {
                    this.$toasted.success("Your Subscription is already activated", {duration: 2000,});
                    router.push({ name: 'home' }).catch(() => {})
                }
            }
        } else{
            this.subcriptionListData().then((response) => {
              this.isPageLoading = false
              console.log(response);
              this.Subscriptionlists = response.data;
              this.msg_title = response.message;
            });
        }
      });
    },
    convertDaysToMonthYear(noOfDays) {
        if(noOfDays <= 30) {
            if(noOfDays == 1){
                return noOfDays + " Day"
            } else{
                return noOfDays + " Days"
            }
        } else if (noOfDays <= 365) {
            return parseInt((noOfDays/30)) + " Month"
        } else {
            return parseInt((noOfDays/365)) + " Year"
        }
    }
  }
}
</script>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


<style>
/*
Inspired by the dribble shot http://dribbble.com/shots/1285240-Freebie-Flat-Pricing-Table?list=tags&tag=pricing_table
*/

/*--------- Font ------------*/
@import url(https://fonts.googleapis.com/css?family=Droid+Sans);
/* fontawesome */
[class*="fontawesome-"]:before {
  font-family: 'FontAwesome', sans-serif;
}
* {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
/*------ utiltity classes -----*/
.card{
  padding: 10px;
}
.fl{ float:left; }
.fr{ float: right; }
/*its also known as clearfix*/
.group:before,
.group:after {
    content: "";
    display: table;
} 
.group:after {
    clear: both;
}
.group {
    zoom: 1;  /*For IE 6/7 (trigger hasLayout) */
}

body {
    background: #F2F2F2;
    font-family: 'Droid Sans', sans-serif;
    line-height: 1;
    font-size: 16px;    
}
.wrapper {
    
}
.price.span{
    position: relative;
    display: inline-block;
    margin-bottom: 0.625rem;
}
.price.span{
    font-size: 2.5rem;
    letter-spacing: 0px;
    font-weight: bold;        
}
.price.sub{
    font-weight: bold;
    font-size: 1.15rem;
    bottom: -1.2%;
    left: 1%;
}

.block.inner{
    width: 100%;
    margin: 2.5% 0 2.5% 2.5%;
    padding: 2.5% 0 2.5% 2.5%;
    overflow: hidden;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
/*    border: 1px solid red;*/
}

.conentbottom{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

#modal-subcription{
  width:100%;
}
</style>